// @flow
import * as PAGES from 'constants/pages';
import * as ICONS from 'constants/icons';
import * as CS from 'constants/claim_search';
import moment from 'moment';
import { toCapitalCase } from 'util/string';
import { CUSTOM_HOMEPAGE } from 'config';

export type HomepageCat = {
  name: string,
  icon: string,
  label: string,
  channelIds?: Array<string>,
  daysOfContent?: number,
  channelLimit?: string,
  pageSize?: number,
  claimType?: string,
  order?: string,
  tags?: Array<string>,
  pinnedUrls?: Array<string>,
  pinnedClaimIds?: Array<string>, // takes precedence over pinnedUrls
  hideSort?: boolean,
  excludedChannelIds?: Array<string>,
  searchLanguages?: Array<string>,
  duration?: string,
  mixIn?: Array<string>,
  hideByDefault?: boolean,
};

function getLimitPerChannel(size, isChannel) {
  if (isChannel) {
    return 1;
  } else {
    return size < 250 ? (size < 150 ? 3 : 2) : 1;
  }
}

export function getAllIds(all: any) {
  const idsSet: Set<string> = new Set();
  (Object.values(all): any).forEach((cat) => {
    if (cat?.channelIds) {
      cat.channelIds.forEach((id) => idsSet.add(id));
    }
  });
  // $FlowFixMe
  return Array.from(idsSet);
}

export const getHomepageRowForCat = (key: string, cat: HomepageCat) => {
  let orderValue;
  switch (cat.order) {
    case 'trending':
      orderValue = CS.ORDER_BY_TRENDING;
      break;
    case 'top':
      orderValue = CS.ORDER_BY_TOP;
      break;
    case 'new':
      orderValue = CS.ORDER_BY_NEW;
      break;
    default:
      orderValue = CS.ORDER_BY_TRENDING;
  }

  let urlParams = new URLSearchParams();
  if (cat.claimType) {
    urlParams.set(CS.CLAIM_TYPE, cat.claimType);
  }
  if (cat.channelIds) {
    urlParams.set(CS.CHANNEL_IDS_KEY, cat.channelIds.join(','));
  }

  const isChannelType = cat.claimType && cat.claimType === 'channel';

  // can intend no limit, numerica auto limit, specific limit.
  let limitClaims;
  if (typeof cat.channelLimit === 'string' && cat.channelIds && cat.channelIds.length) {
    if (cat.channelLimit === 'auto') {
      limitClaims = getLimitPerChannel(cat.channelIds.length, isChannelType);
    } else if (cat.channelLimit) {
      const limitNumber = Number(cat.channelLimit);
      // eslint-disable-next-line
      if (limitNumber === limitNumber && limitNumber !== 0) {
        // because javascript and NaN !== NaN
        limitClaims = Math.floor(limitNumber);
      }
    }
  }

  return {
    id: key,
    link: `/$/${PAGES.DISCOVER}?${urlParams.toString()}`,
    route: cat.name ? `/$/${cat.name}` : undefined,
    icon: cat.icon || '', // some default
    title: cat.label,
    pinnedUrls: cat.pinnedUrls,
    pinnedClaimIds: cat.pinnedClaimIds,
    hideByDefault: cat.hideByDefault,
    hideSort: cat.hideSort,
    options: {
      claimType: cat.claimType || ['stream', 'repost'],
      channelIds: cat.channelIds,
      excludedChannelIds: cat.excludedChannelIds,
      orderBy: orderValue,
      pageSize: cat.pageSize || undefined,
      limitClaimsPerChannel: limitClaims,
      searchLanguages: cat.searchLanguages,
      duration: cat.duration || undefined,
      releaseTime: `>${Math.floor(
        moment()
          .subtract(cat.daysOfContent || 30, 'days')
          .startOf('hour')
          .unix()
      )}`,
    },
  };
};

export function GetLinksData(
  all: any, // HomepageData type?
  isLargeScreen: boolean,
  isHomepage?: boolean,
  authenticated?: boolean,
  showPersonalizedChannels?: boolean,
  showPersonalizedTags?: boolean,
  subscribedChannelIds?: Array<ClaimId>,
  followedTags?: Array<Tag>,
  showIndividualTags?: boolean,
  showNsfw?: boolean
) {
  function getPageSize(originalSize) {
    return isLargeScreen ? originalSize * (3 / 2) : originalSize;
  }

  // $FlowFixMe
  let rowData: Array<RowDataItem> = [];
  const individualTagDataItems: Array<RowDataItem> = [];

  if (isHomepage && showPersonalizedChannels && subscribedChannelIds) {
    const RECENT_FROM_FOLLOWING = {
      id: 'FOLLOWING',
      title: __('Recent From Following'),
      link: `/$/${PAGES.CHANNELS_FOLLOWING}`,
      icon: ICONS.SUBSCRIBE,
      hideSort: false,
      options: {
        orderBy: CS.ORDER_BY_NEW,
        releaseTime:
          subscribedChannelIds.length > 20
            ? `>${Math.floor(moment().subtract(9, 'months').startOf('week').unix())}`
            : `>${Math.floor(moment().subtract(1, 'year').startOf('week').unix())}`,
        pageSize: getPageSize(subscribedChannelIds.length > 3 ? (subscribedChannelIds.length > 6 ? 12 : 8) : 4),
        streamTypes: null,
        channelIds: subscribedChannelIds,
      },
    };
    // $FlowFixMe flow thinks this might not be Array<string>
    rowData.push(RECENT_FROM_FOLLOWING);
  }

  // **************************************************************************
  // **************************************************************************

  const { categories } = all;
  const entries = Object.entries(categories || []);
  for (let i = 0; i < entries.length; ++i) {
    const key = entries[i][0];
    const val = entries[i][1];

    // $FlowIgnore (https://github.com/facebook/flow/issues/2221)
    rowData.push(getHomepageRowForCat(key, val));
  }

  return rowData;
}
